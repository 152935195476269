<template>
  <b-sidebar
    id="add-new-invoice-sidebar"
    :visible="isAddNewInvoiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-invoice-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Додати новий інвойс
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Ціна"
          >
            <b-form-group
              label="Ціна"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="invoiceData.price"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Додати
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Відмінити
            </b-button>
          </div>
          <small class="text-danger mt-1 d-block" v-if="invoiceData.error">Неправильні дані</small>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormRadio, BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,
    BInputGroup,

    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewInvoiceSidebarActive',
    event: 'update:is-add-new-invoice-sidebar-active',
  },
  props: {
    isAddNewInvoiceSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankInvoiceData = {
      price: 0,
      error: false,
    }

    const invoiceData = ref(JSON.parse(JSON.stringify(blankInvoiceData)))
    const resetInvoiceData = () => {
      invoiceData.value = JSON.parse(JSON.stringify(blankInvoiceData))
    }

    const onSubmit = () => {

      store.dispatch('app-invoice/addInvoice', {
          price: parseFloat(invoiceData.value.price) * 100,
        })
        .then((response) => {
          if (response.result == false) {
            invoiceData.value.error = true
          } else {
            emit('refetch-data')
            emit('update:is-add-new-invoice-sidebar-active', false)
          }
        }).catch(() => {
          invoiceData.value.error = true
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetInvoiceData)

    return {
      invoiceData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-invoice-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
